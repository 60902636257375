import React from "react"
import markdownParser from "../../../helpers/markdownParser"
export default function TextContentPreview({ content }) {
  let fontSize = "0.875rem"
  switch (content.size) {
    case "extraSmall":
      fontSize = "0.625rem"
      break
    case "small":
      fontSize = "0.75rem"
      break
    case "base":
      fontSize = "0.875rem"
      break
    case "medium":
      fontSize = "1rem"
      break
    case "large":
      fontSize = "1.1875rem"
      break
    case "extraLarge":
      fontSize = "1.5rem"
      break
  }
  let color = "#625A5F"
  switch (content.color) {
    case "none":
      color = "#625A5F"
      break
    case "success":
      color = "#76A36A"
      break
    case "info":
      color = "#5E94CD"
      break
    case "warning":
      color = "#F3D082"
      break
    case "critical":
      color = "#D24E4C"
      break
    case "accent":
      color = "#31689A"
      break
    case "subdued":
      color = "#898688"
      break
  }
  const paragraphMarkup = (items,index) => {
    return (
      <p>
            {items.map((item, iIndex) => {
             return item.link ? (
                <a url={item.link} key={iIndex} style={{color:"#1773b0", textDecoration:"underline"}}>
                {item.text}
                </a>
              ) : (item.text.map((text, tIndex) => {
                return <span key={tIndex} style={{paddingRight:'3px'}}>{text}</span>
              }
              )
              )
              })}
            </p>
    )
  }
  return (
    <div>
      <div
        style={{
          display: "grid",
          justifyItems: content.alignment,
          fontSize: fontSize,
          color: color,
        }}
      >
        { content.contentText && markdownParser(content.contentText).map((items, index) => {
         return items.list ?  (
            <ul key={index}>
              {
                items.list.map((listItem, listIndex) => {
                  return (
                    <li key={listIndex}>
                      {paragraphMarkup(listItem, listIndex)}
                    </li>
                  )
                })
              }
            </ul>
         ) : (paragraphMarkup(items,index)
         )
        })}
      </div>
    </div>
  )
}
